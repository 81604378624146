<script setup lang="ts">
import { PageName } from "~/utils/enum/page_name";
import { useCategoryListStore } from "~/stores/category_list";
import { CookieName } from "~/utils/enum/cookie_name";
import { IconName } from "~/utils/enum/icon_name";
import { BaseUrl } from "~/utils/enum/base_url";
import { useResolveRoute } from "~/composables/route";
import { useToc } from "~/composables/toc";

const route = useRoute();
const categoryListStore = useCategoryListStore();
const { isToc, tocModel } = useToc(CookieName.CategoryListDisplayAs);
const nuxtApp = useNuxtApp();

definePageMeta({
  name: PageName.Home.toString(),
});

useSeoMeta({
  title: "Главная",
  ogTitle: "Главная",
});

useHead(() => ({
  link: [
    {
      rel: "canonical",
      href: BaseUrl.Alvima + route.path,
    },
  ],
}));

onBeforeMount(async () => {
  // Про костыль сказано в middleware/guest
  const needReload = Boolean(route.query.reload);

  if (needReload) {
    await useResolveRoute(
      {
        name: PageName.Home,
      },
      nuxtApp,
    );
  }
});

onServerPrefetch(async () => {
  await categoryListStore.fetchCategoryList();
});
</script>

<template>
  <div class="page-home">
    <Hero />

    <PartnerList />

    <AboutUsShortly class="pt-16 sm:pt-24 lg:pt-32" />

    <div id="category-list" class="scroll-mt-[100px] pt-16 sm:pt-24 lg:pt-32">
      <UContainer>
        <USeparator />
      </UContainer>

      <div class="pt-12">
        <UContainer class="mb-3 lg:mb-4">
          <h2
            class="text-3xl font-semibold text-(--ui-text-highlighted) mb-4 text-center lg:text-4xl lg:mb-8"
          >
            Каталог
          </h2>

          <ToggleSpecial
            v-model="tocModel"
            :left-icon="IconName.Squares"
            :right-icon="IconName.QueueList"
            :cookie-name="CookieName.CategoryListDisplayAs"
            toggle-max-width="340px"
            class="mx-auto mb-6 lg:mb-8"
          />
        </UContainer>

        <UContainer v-if="isToc" class="max-w-[500px]">
          <CategoryToc :category-list="categoryListStore.getCategoryList" />
        </UContainer>
        <CategoryGrid
          v-else
          :category-list="categoryListStore.getCategoryList"
          tiny
        />

        <UContainer class="flex justify-center mt-12">
          <UButton
            label="Все товары"
            :to="{ name: PageName.AllProducts }"
            size="xl"
            color="neutral"
            variant="subtle"
            :trailing-icon="IconName.ChevronRight"
            external
          />
        </UContainer>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
