export type HeroFeature = {
  title: string;
  text: string;
};

export const heroFeatures: HeroFeature[] = [
  {
    title: "Различных брендов",
    text: "10+",
  },
  {
    title: "Качественных товаров",
    text: "100+",
  },
  {
    title: "Довольных покупателей",
    text: "1,000+",
  },
];
