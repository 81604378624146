<script setup lang="ts">
import { partners } from "~/components/partner/partners";
import { uiPartnerList } from "~/components/partner/ui-partner-list";

const copies = 2;

const ui = computed(() => uiPartnerList());
</script>

<template>
  <div :class="ui.root">
    <UContainer>
      <div class="partner-list-container" :class="ui.container">
        <div
          v-for="idx in copies"
          :key="idx"
          class="partner-list-marquee"
          :class="ui.marquee"
          :aria-hidden="idx > 1"
        >
          <PartnerListItem
            v-for="(partner, idx) in partners"
            :key="idx"
            v-bind="partner"
          />
        </div>
      </div>
    </UContainer>
  </div>
</template>

<style scoped>
.partner-list-container {
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 50px;
    z-index: 10;
  }

  &::after {
    left: 0;
    background: linear-gradient(270deg, rgba(23, 23, 23, 0) 0%, #171717 100%);
  }

  &::before {
    right: 0;
    background: linear-gradient(-270deg, rgba(23, 23, 23, 0) 0%, #171717 100%);
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--partner-list-gap-x)));
  }
}

.partner-list-marquee {
  animation: scroll var(--partner-list-animation-duration) linear infinite;
}
</style>
