<script setup lang="ts">
import { heroFeatures } from "~/components/hero/features";
import { uiHeroFeatureList } from "~/components/hero/ui-hero-feature-list";

const ui = computed(() => uiHeroFeatureList());
</script>

<template>
  <div :class="ui.root">
    <HeroFeatureItem
      v-for="(feature, idx) in heroFeatures"
      :key="idx"
      :feature="feature"
    />
  </div>
</template>

<style scoped></style>
