<script setup lang="ts">
import type { HeroFeature } from "~/components/hero/features";
import { uiFeatureItem } from "~/components/hero/ui-feature-item";

const props = defineProps<{
  feature: HeroFeature;
}>();

const ui = computed(() => uiFeatureItem());
</script>

<template>
  <div :class="ui.root">
    <div :class="[ui.text]">{{ props.feature.text }}</div>
    <div :class="ui.title">{{ props.feature.title }}</div>
  </div>
</template>

<style scoped></style>
