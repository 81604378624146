export const uiHero = () => {
  return {
    root: "bg-(--ui-bg-elevated) pt-12 md:pt-0",
    content: "md:flex md:justify-between md:gap-8",
    left: "md:py-12 md:flex md:flex-col xl:pt-24 xl:pb-12",
    about: "",
    featureList: "pt-10 sm:pt-12 lg:pt-14 xl:pt-16 2xl:pt-[76px]",
    right: "",
    cover: "pt-20 sm:pt-24 md:h-full lg:pt-20 1200:pr-12 1200:pt-16 2xl:pt-24",
  };
};
