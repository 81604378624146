export const uiHeroAbout = () => {
  return {
    root: "",
    title:
      "font-extrabold text-3xl text-(--ui-text-highlighted) uppercase max-w-[500px] sm:text-5xl md:text-4xl lg:text-[44px] lg:leading-none",
    description:
      "text-sm text-(--ui-text-toned) pt-4 max-w-[350px] sm:text-base sm:pt-6",
    actions:
      "pt-6 flex flex-col gap-3 items-center sm:w-fit sm:gap-3 sm:flex-row",
    toCatalog: "g-primary-3d sm:w-fit lg:py-4 lg:px-8",
    downloadPrice: "text-(--ui-text-toned) text-sm flex items-center gap-2",
  };
};
