<script setup lang="ts">
export type PartnerListItemProps = {
  src: string;
  alt: string;
};

const props = defineProps<PartnerListItemProps>();
</script>

<template>
  <div class="partner-list-item">
    <img :src="props.src" :alt="props.alt" />
  </div>
</template>

<style scoped>
.partner-list-item {
  height: 60px;

  img {
    user-select: none;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
}
</style>
