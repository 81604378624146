<script setup lang="ts">
import { IconName } from "~/utils/enum/icon_name";
import { PageName } from "~/utils/enum/page_name";
import { uiAboutUsShortly } from "~/components/about-us/ui-about-us-shortly";

const ui = computed(() => uiAboutUsShortly());
</script>

<template>
  <UContainer>
    <div :class="ui.content">
      <div>
        <div :class="ui.title">
          <span :class="ui.titleSpan">Наивысшее</span> качество и сервис
        </div>
        <div :class="ui.actions">
          <UButton
            label="Связаться с нами"
            size="lg"
            :trailing-icon="IconName.ChevronRight"
            variant="subtle"
            color="neutral"
            class="py-3 px-6"
            :to="{ name: PageName.Contact }"
            external
          />
        </div>
      </div>
      <div :class="ui.textContainer">
        <p>
          <strong>Алвима</strong> — это не просто магазин, где можно приобрести
          комплектующие и расходные материалы для систем мониторинга. Мы
          стремимся стать вашим надежным партнёром.
        </p>
        <p>
          Каждый товар в нашем каталоге проходит тщательную проверку и отбор,
          чтобы Вы могли быть уверены в его качестве. Наша миссия — предоставить
          Вам первоклассный сервис и поддержку, чтобы Вы могли сосредоточиться
          на выполнении своих задач.
        </p>
        <p>
          Мы являемся официальным дистрибьютором датчиков торговой марки
          <NuxtLink :to="{ name: PageName.CategoryList }" external
            ><strong
              class="underline-offset-4 underline transition hover:text-(--ui-error)"
              >ЭСКОРТ</strong
            ></NuxtLink
          >.
        </p>
        <p>
          Если у вас возникнут вопросы, не стесняйтесь
          <NuxtLink :to="{ name: PageName.Contact }" external class="g-link"
            >обращаться</NuxtLink
          >
          к нашим специалистам!
        </p>
      </div>
    </div>
  </UContainer>
</template>

<style scoped></style>
