<script setup lang="ts">
import { PublicAsset } from "~/utils/enum/public_asset";
import { uiHeroCover } from "~/components/hero/ui-hero-cover";

const ui = computed(() => uiHeroCover());
</script>

<template>
  <div :class="ui.root">
    <div :class="ui.image">
      <div :class="[ui.decal, ui.decalLeft]">
        <Image
          :class="ui.decalImage"
          :src="PublicAsset.DecalStar"
          alt="*"
          data-speed="1.1"
        />
      </div>
      <Image :class="ui.imageMain" :src="PublicAsset.HeroEscort" alt="Эскорт" />
      <div :class="[ui.decal, ui.decalRight]">
        <Image
          :class="ui.decalImage"
          :src="PublicAsset.DecalStar"
          alt="*"
          data-speed="1.2"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
