<script setup lang="ts">
import { scrollTo } from "~/utils/html";
import { useDownloadPriceList } from "~/composables/download/price_list";
import { IconName } from "~/utils/enum/icon_name";
import { uiHeroAbout } from "~/components/hero/ui-hero-about";

function goToCategoryList() {
  scrollTo("category-list");
}

const priceList = useDownloadPriceList();

const ui = computed(() => uiHeroAbout());
</script>

<template>
  <div :class="ui.root">
    <h1 :class="ui.title">Комплектующие для мониторинга и тахографии</h1>
    <p :class="ui.description">
      Откройте для себя качественные товары по доступным ценам от опытных
      специалистов.
    </p>
    <div :class="ui.actions">
      <UButton
        label="Перейти к каталогу"
        size="lg"
        :class="ui.toCatalog"
        block
        class="py-3 px-6"
        @click="goToCategoryList"
      />
      <div :class="ui.downloadPrice">
        <span v-if="!priceList.getPriceListIsLoading.value"> или </span>
        <UButton
          :ui="{
            base: 'text-sm p-0 underline underline-offset-4 transition cursor-pointer hover:text-(--ui-info) gap-0',
          }"
          :loading="priceList.getPriceListIsLoading.value"
          color="success"
          variant="link"
          :icon="IconName.FileExcel"
          @click="priceList.downloadPriceList"
          >{{
            priceList.getPriceListIsLoading.value
              ? "скачивание прайса"
              : "скачать прайс"
          }}</UButton
        >
      </div>
    </div>
  </div>
</template>

<style scoped></style>
