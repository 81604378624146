import type { PartnerListItemProps } from "~/components/partner/ListItem.vue";
import { PublicAsset } from "~/utils/enum/public_asset";

export const partners: PartnerListItemProps[] = [
  {
    src: PublicAsset.PartnerLogoMta,
    alt: "MTA",
  },
  {
    src: PublicAsset.PartnerLogoDkc,
    alt: "DKC",
  },
  {
    src: PublicAsset.PartnerLogoTitan,
    alt: "Titan",
  },
  {
    src: PublicAsset.PartnerLogoDonedeal,
    alt: "DoneDeal",
  },
  {
    src: PublicAsset.PartnerLogoAbro,
    alt: "ABRO",
  },
  {
    src: PublicAsset.PartnerLogoVega,
    alt: "Vega",
  },
  {
    src: PublicAsset.PartnerLogoTerminator,
    alt: "Terminator",
  },
  {
    src: PublicAsset.PartnerLogoEscort,
    alt: "Эскорт",
  },
  {
    src: PublicAsset.PartnerLogoKvt,
    alt: "KVT",
  },
  {
    src: PublicAsset.PartnerLogoNavtelekom,
    alt: "Navtelekom",
  },
  {
    src: PublicAsset.PartnerLogoRexat,
    alt: "Rexat",
  },
  {
    src: PublicAsset.PartnerLogoTekcell,
    alt: "Tekcell",
  },
  {
    src: PublicAsset.PartnerLogoXeno,
    alt: "Xeno",
  },
];
