export const uiHeroCover = () => {
  return {
    root: "flex justify-center items-end",
    image:
      "relative w-(--hero-cover-image-width) h-(--hero-cover-image-height)",
    decal: "absolute",
    decalImage: "w-full h-full select-none pointer-events-none",
    decalLeft:
      "top-60 -left-10 w-(--hero-cover-decal-left-size) h-(--hero-cover-decal-left-size) md:top-72 md:-left-2 lg:top-36 lg:-left-12 1200:top-52 1200:-left-14 xl:top-52 xl:-left-24",
    decalRight:
      "top-12 -right-8 w-(--hero-cover-decal-right-size) h-(--hero-cover-decal-right-size) md:-top-10 md:right-4 lg:-top-12 1200:-top-6 1200:-right-6 xl:-top-8 xl:-right-12 2xl:-top-12",
    imageMain: "select-none pointer-events-none object-cover w-full h-full",
  };
};
