export const uiAboutUsShortly = () => {
  return {
    content: "lg:grid lg:grid-cols-[2fr_3fr] lg:gap-12",
    title: "text-3xl text-(--ui-text-highlighted) sm:text-4xl lg:text-5xl",
    titleSpan: "font-bold",
    actions: "mt-4 sm:mt-5 sm:w-full lg:mt-6",
    textContainer:
      "mt-6 sm:mt-12 sm:text-lg lg:mt-0 [&>p:not(:last-child)]:mb-3",
  };
};
