<script setup lang="ts">
import { uiHero } from "~/components/hero/ui-hero";

const ui = computed(() => uiHero());
</script>

<template>
  <div :class="ui.root">
    <UContainer>
      <div :class="ui.content">
        <div :class="ui.left">
          <HeroAbout :class="ui.about" />
          <HeroFeatureList :class="ui.featureList" />
        </div>
        <div :class="ui.right">
          <HeroCover :class="ui.cover" />
        </div>
      </div>
    </UContainer>
  </div>
</template>

<style scoped></style>
